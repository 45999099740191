<template>
  <div>
    <div v-show="showBack" class="back" @click.stop="goBack()">
      <!-- <el-button plain size="small" @click.stop="goBack()">返回</el-button> -->
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">集团名称/简称</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入集团名称"></el-input>
        </div>
        <div class="search-item">
          <span class="label">状态</span>
          <el-select v-model="searchParams.status" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">到期时间</span>
          <el-date-picker
            size="small"
            style="width:370px"
            v-model="searchParams.expireTime"
            @change="changeTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <span class="label">是否测试集团</span>
          <el-select v-model="searchParams.testFlag" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="正式集团" :value="false"></el-option>
            <el-option label="测试集团" :value="true"></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">集团列表</span>
        <el-button type="primary" size="small" @click="goEdit()">+ 新增集团</el-button>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          label="集团名称"
          min-width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showDetail(scope.row)">{{scope.row.hotelName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="hotelShortName"
          label="集团简称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="contactPerson"
          label="关联门店"
        >
          <template slot-scope="scope">
            <div>{{scope.row.hotelInfoList.length}}家</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="状态"
          width="100"
        >
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="changeStatus($event, scope.row)">
              <span class="status-tag" :class="'status' + scope.row.status">
                {{statusMap[scope.row.status]}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in statusOption"
                  :key="item.k"
                  :command="item.k">
                  {{item.v}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactPerson"
          label="联系人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="enableTime"
          label="启用日期"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="expireTime"
          label="到期日期"
          :sortable="true"
          width="130"
        ></el-table-column>
        <el-table-column
          align="center"
          label="是否测试集团"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.testFlag !== null">{{scope.row.testFlag === true ? '测试集团' : '正式集团'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="goEdit(scope.row)">编辑</el-button>
            <el-dropdown trigger="click" @command="handleCommand($event, scope.row)">
              <span class="el-dropdown-link">
                <el-button type="text" style="margin-left: 10px;">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="login" v-if="auth.indexOf('hotel:login:btn') > -1" :class="{'disabled': scope.row.status != 2}">
                  <el-tooltip class="item" :content="reason[scope.row.status]" placement="left" :disabled="scope.row.status == 2">
                    <span>
                      <span class="icon-login"></span>
                      集团登录
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item command="log">
                  <span class="icon-change"></span>
                  变更记录
                </el-dropdown-item>
                <!-- <el-dropdown-item command="recharge">
                  <span class="icon-renew"></span>
                  续期
                </el-dropdown-item> -->
                <el-dropdown-item command="clear" :class="{'disabled': auth.indexOf('hotel:clear:btn') < 0 || scope.row.clearBizDataFlag}">
                  <el-tooltip class="item" :content="auth.indexOf('hotel:clear:btn') < 0 ? '您没有权限操作，请联系管理员' : '历史已操作过数据清空，不可进行二次操作'" placement="left" :disabled="auth.indexOf('hotel:clear:btn') >= 0 && !scope.row.clearBizDataFlag">
                    <span>
                      <span class="icon-login"></span>
                      清空数据
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item command="delete" v-if="scope.row.testFlag" :class="{'disabled': auth.indexOf('hotel:delete:btn') < 0}">
                  <el-tooltip class="item" content="您没有权限操作，请联系管理员" placement="left" :disabled="auth.indexOf('hotel:delete:btn') >= 0">
                    <span>
                      <span class="icon-login"></span>
                      删除测试集团
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>

    <recharge-hotel-dialog :visible="rechargeVisible" :info="rechargeData" @close="hideRecharge" @confirm="confirmRecharge"></recharge-hotel-dialog>
    <detail-hotel-dialog :visible="detailVisible" type="brand" :info="detailData" :productList="productList" @close="hideDetail"></detail-hotel-dialog>
    <change-log-dialog :visible="logVisible" :hotelId="hotelId" @close="hideLog"></change-log-dialog>
    <change-hotel-status-dialog :visible="changeVisible" type="brand" :info="changeData" @close="hideChange" @confirm="confirmChange"></change-hotel-status-dialog>

    <!-- 清空业务数据 弹窗 -->
    <el-dialog
      :visible.sync="clearVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="清空业务数据"
    >
      <div>
        <div class="c-red">
          <div>说明：</div>
          <div>1、业务数据清空只有一次机会，清空后不可再次操作清空，请仔细确认后再进行清空</div>
          <div>2、清空后的数据无法恢复</div>
        </div>
        <div class="flex mt-20">
          <span><span class="c-red">*</span>清空数据对象：</span>
          <div>
            <el-checkbox-group v-model="clearData" disabled>
              <el-checkbox label="clue">线索数据</el-checkbox>
              <el-checkbox label="message">消息通知</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="clearVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="handleClear" :disabled="clearData.length == 0">清空</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { get_key_value, get_area_list, check_config_key } from '@/api/common'
import { get_product_list, post_getSaleUserList } from "@/api/product";
import { bread_brandList, delete_group, clear_group_data } from '@/api/hotel'
// import { get_user_list } from '@/api/user'
import rechargeHotelDialog from './component/recharge-hotel-dialog'
import detailHotelDialog from './component/detail-hotel-dialog'
import changeLogDialog from './component/change-log-dialog'
import changeHotelStatusDialog from './component/change-hotel-status-dialog'
import { pc_url } from '@/config/index'

export default {
  name: 'hotel-list',
  mixins: [pageMixin],
  components: {
    rechargeHotelDialog,
    detailHotelDialog,
    changeLogDialog,
    changeHotelStatusDialog
  },
  data() {
    return {
      statusOption: [],
      statusMap: {},
      salerList: [],
      saler100List: [],
      selectLoading: false,
      productList: [],
      searchParams: {
        search: '',
        status: '',
        expireTimeStart: '',
        expireTimeEnd: '',
        expireTime: '',
        testFlag: ''
      },
      rechargeVisible: false,
      rechargeData: {},
      detailVisible: false,
      detailData: {},
      logVisible: false,
      changeVisible: false,
      changeData: {},
      hotelId: '',
      showBack: false,
      auth: [], // 权限
      reason: {
        1: '集团未到启用时间，无法登录',
        2: '',
        3: '集团已下线，无法登录',
        4: '集团异常，无法登录'
      },
      // expireTime: ''
      clearVisible: false,
      clearData: ['clue', 'message'],
      dialogData: {}, // 弹窗上的数据
    }
  },
  computed: {
    props() {
      return {
        lazy: true,
        label: 'name',
        value: 'code',
        expandTrigger: 'hover',
        lazyLoad: async (node, resolve) => {
          console.log(node)
          const { level, data } = node;
          let params = {}
          if (level == 1) {
            params = {
              areaLevel: "PROVINCE",
              code: data.code
            }
          } else if (level == 2) {
            params = {
              areaLevel: "CITY",
              code: data.code
            }
          }
          let res = []
          if (level == 0 || (data.code && (level == 1 || level == 2))) {
            res = await get_area_list(params)
          }
          res = [{
            name: '全部',
            code: '',
            leaf: true
          }].concat(res)
          if (level == 2) {
            res = res.map(o => {
              return {
                ...o,
                leaf: true
              }
            })
          }
          resolve(res)
        }
      }
    },
  },
  activated() {
    if (this.$store.state.page.keepAlive.indexOf('hotel-list') < 0 || this.list.length == 0) {
      Object.assign(this.$data, this.$options.data())
      this.getAuth()
      this.getProduct()
      this.getOption()
      this.getSaler()
      if (this.$route.query && JSON.stringify(this.$route.query) != '{}') {
        this.showBack = true
        this.searchParams.salerId = this.$route.query.marketId
      }
      this.getList()
    }
  },
  methods: {
    getAuth() {
      check_config_key({
        configKeyList: ['hotel:login:btn', 'hotel:clear:btn', 'hotel:delete:btn'],
        hotelId: 0
      }).then(res => {
        this.auth = res && res.filter(o => { return !!o.hasConfigKey })
                              .map(v => { return v.configKey })
      })
    },
    getProduct() {
      get_product_list()
        .then(res => {
          this.productList = res
        })
    },
    getOption() {
      get_key_value({
        type: 'HOTEL_STATUS'
      }).then(res => {
        this.statusOption = res ? res.records : []
        this.statusMap = this.$arrayToMap(this.statusOption)
      })
    },
    async getSaler(search) {
      const res = await post_getSaleUserList({
        search,
        size: 100,
        pageNo: 1,
      })
      this.selectLoading = false;
      this.salerList = res ? res.records : []
      if (!search) {
        this.saler100List = this.salerList.concat()
      }
    },
    remoteMethod(query) {
      if (query) {
        this.selectLoading = true;
        setTimeout(() => {
          this.getSaler(query.toLowerCase())
        }, 200);
      } else {
        this.salerList = this.saler100List.concat()
      }
    },
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      const res = await bread_brandList(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    goEdit(row) {
      this.$router.push({
        name: 'group-edit',
        query: {
          id: row ? row.id : '',
          form: JSON.stringify(row)
        }
      })
    },
    changeStatus(value, row) {
      console.log(row,'row')
      this.changeData = {
        brandId: row.id,
        status: value,
        statusOldName: this.statusMap[row.status],
        statusName: this.statusMap[value],
        hotelName: row.hotelName
      }
      this.changeVisible = true
    },
    handleCommand(value, row) {
      let l = row.hotelInfoList.filter(o => { return !o.testFlag });
      let str = '';
      switch (value) {
        case 'login':
          if (row.status == 2) {
            window.open(`${pc_url}/#/login?token=${this.$store.state.token}&hotelId=${row.id}`)
            // window.open(`http://192.168.3.115:8081/#/login?token=${this.$store.state.token}&hotelId=${row.id}`)
          } else {
            return
          }
          break
        case 'log':
          this.hotelId = row.id
          this.logVisible = true
          break
        case 'recharge':
          this.rechargeVisible = true
          this.rechargeData = row
          break
        case 'clear':
          if (this.auth.indexOf('hotel:clear:btn') >= 0 && !row.clearBizDataFlag) {
            this.clearVisible = true
            this.dialogData = row
          }
          break
        case 'delete':
          if (this.auth.indexOf('hotel:delete:btn') < 0) {
            return
          }
          if (l.length > 0) {
            str = `${row.hotelName}关联的门店中存在正式门店，请先解绑关联的正式门店再进行删除操作`
          } else {
            str = `${row.hotelName}已关联${row.hotelInfoList.length}家测试门店，将会把测试集团以及关联的所有测试门店一并删除，请确认谨慎操作！`
          }
          this.$confirm(str, '提示', {
            confirmButtonText: l.length > 0 ? '去解绑' : '确定',
            cancelButtonText: '取消',
            customClass: 'dialog-class',
            center: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                if (l.length > 0) {
                  this.goEdit(row)
                  done()
                } else {
                  instance.confirmButtonLoading = true;
                  delete_group({
                    brandId: row.id
                  }).then(() => {
                    this.$message.success('删除成功')
                    this.getList()
                    instance.confirmButtonLoading = false;
                    done()
                  }).catch(err => {
                    instance.confirmButtonLoading = false;
                    if (err.code == 300001) {
                      instance.message = err.data.message
                      instance.confirmButtonText = '去删除/解绑'
                      instance.beforeClose = (action, instance, done) => {
                        if (action === 'confirm') {
                          this.$router.push({
                            name: 'minip-list'
                          })
                        }
                        done()
                      }
                    } else {
                      this.$message.error(err.msg || "请求错误");
                    }
                  })
                }
              } else {
                done()
              }
            }
          }).then(action => {
            console.log(action)
          })
          break
      }
    },
    hideRecharge() {
      this.rechargeVisible = false
      this.rechargeData = {}
    },
    confirmRecharge() {
      this.getList()
      this.hideRecharge()
    },
    hideChange() {
      this.changeVisible = false
      this.changeData = {}
    },
    confirmChange() {
      this.getList()
      this.hideChange()
    },
    showDetail(row) {
      this.detailData = row
      this.detailVisible = true
    },
    hideDetail() {
      this.detailData = {}
      this.detailVisible = false
    },
    hideLog() {
      this.hotelId = ''
      this.logVisible = false
    },
    goBack() {
      const keepAlive = this.$store.state.page.keepAlive.concat(['marketer-list'])
      this.$store.commit('page/SET_KEEPALIVE', keepAlive)
      this.$router.go(-1)
    },
    changeTime(val) {
      if(val) {
        this.searchParams.expireTimeStart = val[0]
        this.searchParams.expireTimeEnd = val[1]
      } else {
        this.searchParams.expireTimeStart = ''
        this.searchParams.expireTimeEnd = ''
      }
      // console.log(val,11)
    },
    handleClear() {
      this.$confirm(`
        <div>
          <div>1、清空数据后将无法恢复，请谨慎操作</div>
          <div>2、确认清空后，清空数据功能将会置灰，不可二次操作</div>
        </div>
      `, '二次确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        dangerouslyUseHTMLString: true,
        center: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            clear_group_data({
              brandId: this.dialogData.id
            }).then(() => {
              this.clearVisible = false
              this.$message.success('清空成功')
              this.getList()
              instance.confirmButtonLoading = false;
              done()
            })
          } else {
            done()
          }
        }
      }).then(action => {
        console.log(action)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.status-tag{
  font-size: 12px;
  width: 75px;
  line-height: 32px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  &.status1{ // 新开户
    color: #568DC5;
    background: #EFF7FE;
  }
  &.status2{ // 营业中
    color:#64A264;
    background: #EDFBEC;
  }
  &.status3{ // 已下线
    color: #666666;
    background: #F1F1F1;
  }
  &.status4{ // 异常
    color: #B52929;
    background: #FAF1F1;
  }
}
.icon-login{
  width: 11px;
  height: 13px;
  display: inline-block;
  background: url('../../assets/icons/icon-login1.png');
  vertical-align: -2px;
}
.icon-change{
  width: 11px;
  height: 13px;
  display: inline-block;
  background: url('../../assets/icons/icon-change1.png');
  vertical-align: -2px;
}
.icon-renew{
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url('../../assets/icons/icon-renew1.png');
  vertical-align: -2px;
}
::v-deep .el-dropdown-menu__item:hover{
  .icon-login{
    background: url('../../assets/icons/icon-login2.png');
  }
  .icon-change{
    background: url('../../assets/icons/icon-change2.png');
  }
  .icon-renew{
    background: url('../../assets/icons/icon-renew2.png');
  }
}
::v-deep .disabled{
  color: #bbb !important;
  background: #fff !important;
  cursor: no-drop;
  &.el-dropdown-menu__item:hover{
    .icon-login{
      background: url('../../assets/icons/icon-login1.png');
    }
  }
}
.back {
  // width: 100%;
  margin-bottom: 10px;
  background: white;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :first-child {
    margin-right: 5px;
  }
}
.c-red{
  color: #E14B38;
}
</style>